.game-over {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
    height: 40%;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .popup-content {
    text-align: center;
  }
  
  .restart-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 140px;
    font-size: 1rem;
  }
  
  .restart-button:hover {
    background-color: #0056b3;
  }
  
  .word-chain {
    margin: 10px 0;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .chain-symbol {
    margin-right: 4px;
  }