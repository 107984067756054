.howto-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 60%;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .howto-content {
    text-align: center;
  }
  
  .close-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 50%;
    font-size: 1rem;
  }
  
  .close-button:hover {
    background-color: #0056b3;
  }
  